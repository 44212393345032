/* Reset and general styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
}

.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 20px;
}

.header {
    margin-bottom: 30px;
}

.logo {
    width: 120px;
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hero h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 30px;
}

.cta-buttons {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.cta-button img {
    width: 120px;
}

.footer {
    position: absolute;
    bottom: 20px;
    font-size: 14px;
}